import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import WrapperPropTypes from 'src/utils/propTypes'
import Seo from 'src/components/Seo'
import PatientsTemplate from 'src/templates/patients'

const PatientsWrapper = ({ data }) => {

  const page = data.wpPage
  const { seo } = page

  let ogImage = null
  if (seo.opengraphImage) {
    ogImage = {
      alt: seo.opengraphImage.altText,
      url: seo.opengraphImage.mediaItemUrl,
      width: seo.opengraphImage.mediaDetails.width,
      height: seo.opengraphImage.mediaDetails.height,
      type: seo.opengraphImage.mimeType,
    }
  }

  let twitterImage = null
  if (seo.twitterImage) {
    twitterImage = {
      alt: seo.opengraphImage.altText,
      url: seo.opengraphImage.mediaItemUrl,
      width: seo.opengraphImage.mediaDetails.width,
      height: seo.opengraphImage.mediaDetails.height,
      type: seo.opengraphImage.mimeType,
    }
  }

  let readMore;

  const options = data.allWp.edges[0].node.siteGeneralSettings.acfOptions;

  switch (data.wpPage.language.locale) {
    case 'en_US':
      readMore = options.readMoreEn
      break
    case 'ja':
      readMore = options.readMoreJa
      break
    default:
      readMore = options.readMoreDe
      break
  }

  return (
    <Layout page={data.wpPage} pageLocale={data.wpPage.language.locale}>
      <Seo
        title={page.title}
        locale={page.language.locale}
        description={seo.metaDesc}
        canonicalUri={data.wpPage.uri}
        og={{
          title: seo.opengraphTitle,
          description: seo.opengraphDescription,
          url: seo.opengraphUrl,
          image: ogImage,
          type: seo.opengraphType,
        }}
        twitter={{
          title: seo.twitterTitle,
          description: seo.twitterDescription,
          image: twitterImage,
        }}
      />
      <PatientsTemplate acf={data.wpPage.acfPatients} readMoreLabel={readMore} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PatientsById($id: String!) {
    ...metadata
    ...readMoreLabel
    wpPage(id: { eq: $id }) {
      title
      acfPatients {
        ...patientsIntro
        ...patientsTherapy
        ...patientsStudies
        ...patientsEfficacySaftey
        # ...patientsBlog
        ...patientsFaq
      }
      seo {
        metaDesc
      }
      ...pageTranslationData
    }
  }
`

PatientsWrapper.propTypes = WrapperPropTypes

export default PatientsWrapper
