import React from 'react'
import PropTypes from 'prop-types'
import Pattern from 'src/components/Pattern'
import InfoIcon from 'src/img/information.svg'

import { Box, Icon } from './style'

const InfoBox = ({ children }) => (
  <Pattern positionY="bottom">
    <Box px={{ xs: 2, lg: 4 }} py={{ xs: 4, lg: 10 }} mt={{ xs: 8, lg: 8 }}>
      <Icon src={InfoIcon} alt="" />
      {children}
    </Box>
  </Pattern>
)

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InfoBox
