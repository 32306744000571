import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = styled(MuiBox)`
  background-color: ${props => props.theme.color.lightblue};
  position: relative;
`

export const Icon = styled.img`
  background-color: ${props => props.theme.color.green};
  border-radius: 50%;
  height: 78px;
  width: 78px;
  padding: 16px;
  position: absolute;
  top: -39px;
`
