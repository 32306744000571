import React from 'react'
import PropTypes from 'prop-types'
import { AccordionSummary } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import { TextBold } from 'src/styles/typography'
import Wysiwyg from 'src/components/Wysiwyg'
import trinagleIcon from 'src/img/triangle.svg'
import { AccordionCard, AccordionBody } from './style'

const Accordion = ({ heading, textWysiwyg }) => {
  const key = uuidv4()

  return (
    <AccordionCard square>
      <AccordionSummary
        expandIcon={<img src={trinagleIcon} alt="" />}
        aria-controls={`panel${key}a-content`}
        id={`panel${key}a-header`}
      >
        <TextBold>{heading}</TextBold>
      </AccordionSummary>
      <AccordionBody>
        <Wysiwyg>{textWysiwyg}</Wysiwyg>
      </AccordionBody>
    </AccordionCard>
  )
}

Accordion.propTypes = {
  heading: PropTypes.string.isRequired,
  textWysiwyg: PropTypes.string.isRequired,
}

export default Accordion
