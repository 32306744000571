import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Box, Grid } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { getThemeVar } from 'src/styles/mixins'
import Section from 'src/components/Section'
import SectionPattern from 'src/components/SectionPattern'
import ImageWithBackground from 'src/components/ImageWithBackground'
import Heading2Line from 'src/components/Heading2Line'
import Wysiwyg from 'src/components/Wysiwyg'
// import ExternalLinkButton from 'src/components/ExternalLinkButton'
import InfoBox from 'src/components/InfoBox'
// import PostGrid from 'src/components/PostGrid'
import Accordion from 'src/components/Accordion'

export const PatientsTemplate = ({ acf }) => {
// export const PatientsTemplate = ({ acf, readMoreLabel }) => {
  const intro = acf.patientsIntro
  const therapy = acf.patientsTherapy
  // const studies = acf.patientsStudies
  const efficacySaftey = acf.patientsEfficacySaftey
  // const blog = acf.patientsBlog
  const faq = acf.patientsFaq

  const breakpoints = useBreakpoint()

  return (
    <>
      <Section background={getThemeVar('color.lightblue')} hasPadding>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          spacing={5}
        >
          <Grid item xs={12} md={6}>
            <Heading2Line
              preHeading={intro.preHeading}
              heading={intro.heading}
            />
            <Wysiwyg>{intro.textWysiwyg}</Wysiwyg>
          </Grid>
          <Grid item xs={12} md={6}>
            { intro.image && intro.image.localFile && intro.image.localFile.childImageSharp && (
              <ImageWithBackground
                imgFluid={intro.image.localFile.childImageSharp.fluid}
                alt={intro.image.altText}
                position="right"
              />
            )}
          </Grid>
        </Grid>
        <SectionPattern positionX="right" />
      </Section>

      <Section hasPaddingLarge background={getThemeVar('color.white')}>
        <Grid container>
          <Grid item xs={12} lg={10}>
            <Heading2Line
              preHeading={therapy.preHeading}
              heading={therapy.heading}
            />
            {breakpoints.lg ? (
              <Img
                fluid={therapy.imageDesktop.localFile.childImageSharp.fluid}
                alt={therapy.imageDesktop.altText}
              />
            ) : (
              <Img
                fluid={therapy.imageMobile.localFile.childImageSharp.fluid}
                alt={therapy.imageMobile.altText}
              />
            )}
            <Wysiwyg>{therapy.textWysiwyg}</Wysiwyg>
          </Grid>
        </Grid>
        <SectionPattern positionX="left" />
      </Section>

      {/* Hidden Studies Section
      <Section hasPaddingLarge background={getThemeVar('color.lightblue')}>
        <Heading2Line
          preHeading={studies.preHeading}
          heading={studies.heading}
        />
        <Box mt={{ xs: 0, lg: 10 }}>
          <Grid container spacing={3}>
            {studies.studies.map(study => (
              <Grid item xs={12} lg={6} key={study.link.title}>
                <ExternalLinkButton to={study.link.url}>
                  {study.link.title}
                </ExternalLinkButton>
              </Grid>
            ))}
          </Grid>
        </Box>
        <SectionPattern positionX="right" />
      </Section>
      */}

      <Section hasPaddingLarge background={getThemeVar('color.white')}>
        <Heading2Line
          preHeading={efficacySaftey.preHeading}
          heading={efficacySaftey.heading}
        />
        <InfoBox>{ReactHtmlParser(efficacySaftey.textWysiwyg)}</InfoBox>
        <SectionPattern positionX="left" />
      </Section>

      {/* Hidden Blog Section
      <Section hasPaddingLarge background={getThemeVar('color.lightblue')}>
        <Heading2Line preHeading={blog.preHeading} heading={blog.heading} />
        <PostGrid posts={blog.posts} readMoreLabel={readMoreLabel} />
        <SectionPattern positionX="right" />
      </Section>
      */}

      <Section
        hasPaddingLarge
        background={getThemeVar('color.white')}
        paddingValuesLarge={{ xs: '48px 0  96px 0', lg: '100px 0 148px 0' }}
      >
        <Heading2Line preHeading={faq.preHeading} heading={faq.heading} />
        <Box mt={{ xs: 0, lg: 10 }}>
          <Grid container spacing={2}>
            {faq.faqs.map((question, i) => (
                <Grid item xs={12} lg={12} key={question.heading}>
                  <Accordion
                    heading={question.heading}
                    textWysiwyg={question.textWysiwyg}
                    i={i}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Section>
    </>
  )
}

PatientsTemplate.propTypes = {
  acf: PropTypes.shape({
    patientsIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      image: PropTypes.object, //eslint-disable-line
    }),

    patientsTherapy: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      imageDesktop: PropTypes.object.isRequired, //eslint-disable-line
      imageMobile: PropTypes.object.isRequired, //eslint-disable-line
      textWysiwyg: PropTypes.string,
    }),

    patientsStudies: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      studies: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          }).isRequired,
        })
      ).isRequired,
    }),

    patientsEfficacySaftey: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
    }),

    // patientsBlog: PropTypes.shape({
    //   preHeading: PropTypes.string,
    //   heading: PropTypes.string,
    //   posts: PropTypes.arrayOf(
    //     PropTypes.shape({
    //       featuredImage: PropTypes.shape({
    //         node: PropTypes.object, //eslint-disable-line
    //       }),
    //       title: PropTypes.string,
    //       excerpt: PropTypes.string,
    //       link: PropTypes.string.isRequired,
    //     })
    //   ).isRequired,
    // }),

    patientsFaq: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      faqs: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          textWysiwyg: PropTypes.string,
        })
      ).isRequired,
    }),
  }).isRequired,
  // readMoreLabel: PropTypes.string,
}

// PatientsTemplate.defaultProps = {
//   readMoreLabel: "",
// }

export default PatientsTemplate
