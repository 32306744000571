import styled from 'styled-components'
import { Accordion as MuiAccordion, AccordionDetails } from '@material-ui/core'

export const AccordionCard = styled(MuiAccordion)`
  background-color: ${props => props.theme.color.lightblue};
  box-shadow: none;
`

export const AccordionBody = styled(AccordionDetails)`
  border-top: 1px solid ${props => props.theme.color.lightgrey};
`
